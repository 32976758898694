$(document).ready(function () {
    __siteJS.run();
    __flickityCustomNavJS.run();
    __desktopMenuJS.run();
    __toggleMenuJS.run();
});

$(window).on('load', function () {
});

$(window).on('resize', function () {
});
