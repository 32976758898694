const DesktopMenuJS = function () {

    const menu = $('.site-head');
    const dropdownMenu = $('.head-dropdown-menu');
    let menulink;
    let timeout;

    const hide = function (menuitem) {
        dropdownMenu.stop(true, false).removeClass('show').delay(400).queue(function () {
            dropdownMenu.stop(true, false).removeClass('active');
        })
        menu.find('.head-menu__item-link.active').removeClass('active');
    };

    const show = function (menuitem) {
        dropdownMenu.stop(true, false).addClass('active').delay(100).queue(function () {
            dropdownMenu.stop(true, false).addClass('show');
        })
        menuitem.find('.head-menu__item-link').addClass('active');
    }

    const init = function () {
        menu.find('.head-menu__item').hover(function () {
            const dataid = $(this).attr('data-dropdown-id');
            menulink = $(this);

            if (dropdownMenu.find('[data-dropdown-id="' + dataid + '"]').length === 0) {
                hide(menulink);
            } else {
                menu.hover(function () {
                    show(menulink);
                }, function (e) {
                    console.log('hide 1');
                    hide(menulink);

                    menu.unbind('mouseenter mouseleave')
                });

                show(menulink);
                dropdownMenu.find('div[data-id]').addClass('d-none');
                dropdownMenu.find('div[data-id="' + $(this).attr('data-id') + '"]').removeClass('d-none');
            }
        }, function (e) {
            if (!$(e.relatedTarget).hasClass('head-dropdown-menu__inner')) {
                hide($(this));
            }
        })

        dropdownMenu.hover(function () {

        }, function (e) {
            if (!$(e.relatedTarget).hasClass('head-menu__item-link active')) {
                hide();
            }
        })
    };


    this.run = function () {
        init();
    };

};

const __desktopMenuJS = new DesktopMenuJS();
