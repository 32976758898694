let SiteJS;
const window_width = $(window).width();
const window_sm    = 576;
const window_md    = 768;
const window_lg    = 992;
const window_xl    = 1200;
const window_xxl    = 1400;

SiteJS         = function () {

    const plugins = function () {
        $(':input').inputmask();
        AOS.init();
    };

    const rating = function () {
        $('[data-rate]').each(function (key, value) {
            $(value).awesomeRating({
                values: [1, 2, 3, 4, 5],
                valueInitial: parseFloat($(value).attr('data-rate')),
                cssBase: "rating-star fa fa-star",
                cssBaseSelected: "text-warning",
                cssBaseUnselected: "text-gray-400",
                readonly: true,
                allowFractional: true,
                calculateFractional: function (current, rate) {
                    if (Math.ceil(current) === rate) {
                        return 0.6
                    }
                    return 1;
                }
            });
        });
    }

    const stickyHeader = function () {
        $('.head-block--left').headroom({
            // vertical offset in px before element is first unpinned
            // or you can specify offset individually for up/down scroll
            offset: {
                up: 100,
                down: 50
            },
            // scroll tolerance in px before state changes
            // or you can specify tolerance individually for up/down scroll
            tolerance: {
                up: 5,
                down: 0
            }
        });
    };

    this.addActiveClassUrl = function (url) {
        $('[href="' + url + '"]').addClass('is-active');
    }

    this.headSearchBoxToggle = function (el) {
        const parent = $(el).parents('.head-search');

        if (parent.hasClass('is-show')) {
            const input = parent.find('input[type="text"]');
            if (input.val().length === 0) {
                parent.removeClass('is-show');
                parent.find('input[type="text"]').blur();
                parent.find('button[type="submit"]').attr('type', 'button');
            }
        } else {
            parent.addClass('is-show');
            parent.find('input[type="text"]').focus();
            setTimeout(function () {
                parent.find('button[type="button"]').attr('type', 'submit');
            })
        }
    };

    this.run = function () {
        plugins();
    };

    this.resize = function () {

    };

    this.load = function () {

    };

};
const __siteJS = new SiteJS();


